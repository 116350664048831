import moment from "moment"

//This function returns true, if current India time is less than given foreign time, else returns false
const checkIndAndAbroadTime = eventTimeIndia => {
  let currentTime, offsetTime, eventUTC, eventTimeForeign

  currentTime = new Date()

  offsetTime = currentTime.getTimezoneOffset()

  eventUTC = moment(eventTimeIndia).add("-330", "minutes")._d

  eventTimeForeign = moment(eventUTC).add(parseInt(offsetTime) * -1, "minutes")
    ._d

  return currentTime < eventTimeForeign
}

const slugChecker = (showHellobar, pageSlugs) => {
  if (showHellobar === "all_pages") return true
  else if (showHellobar === "not_contains") {
    return !withStarWithoutStar(pageSlugs)
  } else if (showHellobar === "contains") {
    return withStarWithoutStar(pageSlugs)
  }
}

//This function returns true, if current page's slug matches, else returns false.
const withStarWithoutStar = pageSlugs => {
  const pathName = window.location.pathname
  let slugWithStar = [],
    slugWithoutStar = [],
    checkerOne = [],
    checkerTwo = []
  pageSlugs.map(slug => {
    slug.slice(-1) === "*"
      ? slugWithStar.push(slug)
      : slugWithoutStar.push(slug)
  })
  if (slugWithStar.length)
    checkerOne = slugWithStar.filter(
      slug => slug.slice(0, -1) === pathName.slice(0, slug.length - 1)
    )

  if (slugWithoutStar.length)
    checkerTwo = slugWithoutStar.filter(slug => slug === pathName)

  return checkerOne.length || checkerTwo.length
}

const preventInitialZero = (value) =>{
  if(/^0/.test(value)){
    return value.replace(/^0/, "")
  }
  return value
}

export { checkIndAndAbroadTime, preventInitialZero, slugChecker }

